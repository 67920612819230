html, body {
	font-family: 'Raleway', sans-serif;
	letter-spacing:0.4px;
	background: url(../images/noise.png) repeat;
}
p {
	color: #000;
	font-family: "Open sans";
	font-size: 16px;
	line-height: 24px;
}
.white-bg {
	background:#fff;
	margin-left:15px;
	margin-right:15px;
	overflow:hidden;
}
.title {
	font-family: 'Raleway', sans-serif;
}
.title span {
	color:#000;
	font-weight:bold;
}
#footer .title span {
	color:#0e76bc;
}
.header-message {
	font-size:16px;
}
#wrapper {
	max-width:1170px;
	margin:0 auto;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	background-color:#fff;
}
.top {
	border-top:6px solid #0e76bc;
}
#header-contact {
	background:url(../images/headers/sub-header.jpg)no-repeat;
	padding:30px 0;
}
#header-info {
	background:url(../images/headers/sub-header.jpg)no-repeat;
	padding:30px 0;
}
#header-product {
	background:url(../images/headers/product-header.jpg) right center no-repeat;
	padding:120px 0;
}
#header-product .title-container{
	margin-right: 600px;
}
@media (max-width: 1100px){
	#header-product {
		background:#9BBEBC;
	}
	#header-product .title-container{
		margin-right: 0px;
	}
}
#header-product h1 {
	margin:0;
}
#top-bar {
	background-color:#0e76bc;
	color:#fff;
	padding:7px;
}
.padding-top {
	padding-top:30px;
}
#top-bar p {
	margin:0px;
	color: #fff;
	font-size: 14px;
}
#nav-bg {
	background-color:#0e76bc;
}
.top-bar-margin-fix {
	margin-top:8px !important;
}
.content-margin {
	padding-top:20px;
	padding-bottom:20px;
}
.social-icon {
	width: 37px;
	height: 37px;
	text-align: center;
	background-color: white;
	border: 3px solid #39b54a;
	font-family: 'FontAwesome';
	font-size: 16px;
	color: #39b54a;
	padding-top: 8px;
	transition: all 0.5s;
	-moz-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	border-radius: 99px;
	-webkit-border-radius: 99px;
	-o-border-radius: 99px;
}
.logo {
	margin-top:25px;
}
.navbar-nav>li {
	border-left:1px solid #478dbc;
}
.navbar-default {
	background-color:#0e76bc;
	border:0;
	margin-top:0px;
	border-radius:0;
}
.navbar {
	margin-bottom:0;
}
.navbar-default .navbar-nav>li>a {
	color:#fff;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
	background-color:#8fbfe0;
	color:#fff;
}
.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
	background-color:#8fbfe0;
	color:#fff;
}
.clear {
	clear:both;
}
.no-padding {
	padding:0;
}
.logo-padding {
	padding:15px;
}
.contact-padding {
	padding:30px;
	margin-top:60px;
}
.social {
	font-size:16px;
	color:#aaa;
}
i {
	margin-left:5px;
}
#home-boxes {
	margin-top:0px;
	margin-bottom:0px;
}
#home-boxes img {
	border-bottom:3px solid #0e76bc;
	margin-bottom:10px;
}
#home-boxes .img-thumbnail {
	border-radius:0;
}
#home-boxes h2 {
	font-size:20px;
}
.separator {
	margin: 0 auto;
	width: 70px;
	height: 3px;
	background: #0e76bc;
	margin: 6px auto 20px;
	float: left;
}
#home-boxes h1 {
	text-transform:uppercase;
	font-size:24px;
	margin:0;
}
.btn-connect {
	border:1px solid #0e76bc;
	color:#0e76bc;
	border-radius:0;
	transition:0.2s ease;
}
.btn-connect:hover {
	color:#333;
	border:1px solid #333;
}
.gap {
	border-top:1px solid #eee;
	margin-top:20px;
	margin-bottom:20px;
}
.well {
	border-radius:0;
	background-color:#fcfcfc;
	border-bottom:3px solid #0e76bc;
	border:1px solid #ccc;
}
#parallax {
	margin-top:0px;
	background-color:#333;
	color:#333;
	padding:50px;
	background:url(../images/parallax.jpg)fixed;
}
#footer {
	margin-top:0px;
	margin-bottom:0px;
	border-top:1px solid #ccc;
	padding:100px 0;
	background:url(../images/footer.jpg)no-repeat;
	-webkit-background-size:cover;
	background-size:cover;
	color:#fff;
}
#footer h1 {
	font-size:20px;
	margin:0;
	padding:10px 0 0 0;
}
#copyright {
	background-color:#fff;
	color:#aaa;
	padding:10px;
}
#copyright p {
	margin:0;
	font-size:12px;
}
.logo-restrain {
	max-width: 150px;
	margin-bottom: 30px;
}
.modal-content {
	border: 5px solid rgba(255,255,255,0.5);
	border-radius: 16px;
	color: #888;
	font-size: 18px;
}
.fancy {
	line-height: 0.5;
	text-align: center;
}
.fancy span {
	display: inline-block;
	position: relative;
	margin-bottom: 40px;
}
.fancy span:before, .fancy span:after {
	content: "";
	position: absolute;
	height: 5px;
	border-top: 2px solid #888;
	top: 3px;
	width: 120px;
}
.fancy span:before {
	right: 100%;
	margin-right: 15px;
}
.fancy span:after {
	left: 100%;
	margin-left: 15px;
}
.form-control {
	height: 45px;
}
.margin-correction {
	margin-top: 20px;
}
.input-group-addon {
	background: #757575;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodâ€¦EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, #757575 1%, #b2b2b2 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,#757575), color-stop(100%,#b2b2b2));
	background: -webkit-linear-gradient(top, #757575 1%,#b2b2b2 100%);
	background: -o-linear-gradient(top, #757575 1%,#b2b2b2 100%);
	background: -ms-linear-gradient(top, #757575 1%,#b2b2b2 100%);
	background: linear-gradient(to bottom, #757575 1%,#b2b2b2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#757575', endColorstr='#b2b2b2',GradientType=0 );
	border: 0;
	padding: 10px 17px;
	color: #fff;
}
.text-small-margin {
	font-size: 12px;
	margin-top: 10px;
}
.modal-header {
	border: 0;
}
@media (max-width:400px) {
	.no-padding {
		padding-left:5px;
		padding-right:5px;
	}
}
#parallax img {
	border-bottom: 3px solid #0e76bc;
	margin-bottom: 10px;
}
#parallax h2 {
	font-size: 20px;
}
.form-login {
	background-color: #EDEDED;
	padding-top: 10px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 0;
	border-color:#d2d2d2;
	border-width: 5px;
	box-shadow:0 1px 0 #cfcfcf;
	overflow:auto;
}
#login {
	margin-bottom:50px;
}
#login h2 {
	margin:0;
}
#login h4 {
	margin:0;
	margin-top:10px;
}
.header {
	margin-bottom:30px;
}
.input-sm {
	border-radius:0;
}
label {
	font-weight:normal;
}
select.input-sm {
	height:45px;
}
h2 {
	margin:0;
}
/* START FORM */
/*-----COPY CODE BELOW INTO YOUR EXISTING STYLESHEET-----*/
#form {
	width: 100%;
}

/*-----error/success messages-----*/
#error {
	display:none;
	background: #e74c3c;
	color:#FFF;
	padding:25px;
	margin-bottom: 25px;
}

#success{
	display:none;
	background: #2ecc71;
	color:#FFF;
	padding:25px;
	margin-bottom: 25px;
}

/*-----form elements-----*/
#easy {
	float:left;
	max-width: 100%;
	margin: auto;
}

#easy .input {
	width: 49%;
	float: left;
	margin: 0 0 20px 0;
}

#easy .textarea {
	width: 100%;
	margin: 0 0 15px 0;
}

#easy .last {
	float: right;
}

#easy input.text,
#easy textarea {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight:300;
	font-size:1em;
	padding: 8px 8px;
	border: 1px solid #cecece;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	float: left;
	margin: 0;
	outline: none;
}


#easy input[type=text]:focus,
#easy input[type=tel]:focus,
#easy textarea:focus {
	margin-right: 0px;
	box-shadow: 0 0 3px rgba(52, 152, 219, 1);
	border: 1px solid rgba(52, 152, 219, 1);
}

#easy input:hover,
#easy textarea:hover {
	border: 1px solid #3498db;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

#easy textarea {
	height: 200px;
}

#easy span {
	color:red;
}

#easy .verification {
	width: 100%;
	height: 42px;
	float: left;
}

#easy #vimage {
	width: 99px;
	height: 42px;
	float: left;
}

#easy .refresh {
	width: 42px;
	height: 42px;
	display: block;
	float: left;
	margin: 0 7px;
	border:0;
}

#easy #verify {
	width: 145px;
	height: 40px;
	float: left;
	margin: 0;
	padding:8px;
}

#easy button {
	float: right;
	display: block;
	width: 125px;
	background: #3498db;
	padding: 12px 20px 7px !important;
	color: #fff;
	box-shadow: 0 7px 0 #007dbd;
	text-align: center;
	margin:0;
	border:0;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
	cursor:pointer;
}

#easy button:hover {
	background: #0064a2;
	box-shadow: 0 7px 0 #004c87;
}
.contact-header {
	background-image:url(../images/headers/sub-header.jpg);
	height:225px;
}
#footer p {
	color:#fff;
	font-size:14px;
}
ul.tick {
	padding:0;
	list-style:none;
}
ul.tick li {
	margin-bottom:5px;
}
ul.tick li i {
	color:#0e76bc;
}
.margin-big {
	padding-top:50px;
	padding-bottom:50px;
}
.fade2 {
	opacity:1;
	transition:0.2s ease;
}
.fade2:hover {
	opacity:0.65;
}
.small {
	font-size:10px;
	font-weight:bold;
}
.downloads img {
	margin-bottom:5px;
	border:1px solid #ccc;
}
.downloads p {
	font-size:10px;
	line-height:normal;
}
.references p {
	font-size:10px;
	line-height:normal;
}